import React, {Component} from "react"

import {graphql, useStaticQuery} from 'gatsby'

import Layout from '../containers/layout'

import Container from '../components/container'
import MetaSchemaSEO from '../components/seo';

import * as styles from './contact.module.css'

export const query = graphql`
  query {
    site {
      siteMetadata {
      siteUrl
      }
    }
  }
`


class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newComment: {
        fname: "",
        lname: "",
        email: "",
        phone: "",
        message: "",
        slug: this.props.slug
      },
      submitting: false,
      success: false,
      error: false,
    }
    //this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    //this.setState({value: event.target.value});
    const { newComment } = this.state
    const { name, value } = event.target

    this.setState({
      newComment: { ...newComment, [name]: value },
    })
  }

  //handleSubmit(event) {
  handleSubmit = async event => {


    event.preventDefault();

    // todo validate fields!
    // todo check captcha before sending

    // Set this so the button can't be pressed repeatedly
    this.setState({ submitting: true })

    const { newComment } = this.state
    const { slug, page, domain, project_id } = this.props

    try {
      // POST to /comments
      
      const response = await fetch("https://api.contentcurator.com/v1/messages/inbox", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify({
              "project_id":project_id,
              "to":"info@"+domain,
              "from":newComment.email,
              "type":"contactus",
              "title":"Contact Us Form Submit",
              "page":page,
              "data":newComment}),
      })
  
      // Append comment and reset newComment
      this.setState(prevState => ({
        ...prevState,
        newComment: {
          fname: "",
          lname: "",
          email: "",
          phone: "",
          message: "",
          slug: this.props.slug
        },
        success: true,
        error: false,
      }))
    } catch (error) {
      this.setState({ ...this.initialState, error: true })
    }
    // end onSubmit
  }


  render() {
    const { submitting, success, error, comments, newComment: { name, text } } = this.state

    const showError = () =>
      error && (
        <div className={styles.error}>
          <h1>Sorry</h1>
          <p>Comment failed to submit.</p>
          <p>Please refresh and try again.</p>
        </div>
      )

    const showSuccess = () =>
      success && (
        <div className={styles.success}>
          <h1>Thank You</h1>
          <p>Comment submitted!</p>
        </div>
      )
      
    const commentForm = () => (
      <>
        <div class="text-center mb-5 mb-md-9">
          <h2>Tell us about yourself</h2>
          <p>Whether you have questions or you would just like to say hello, contact us.</p>
        </div>
        <form onSubmit={this.handleSubmit} onChange={this.handleChange}>
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles.box}>
                <label class="form-label" htmlFor="fname">First name</label>
                <input required type="text" className={styles.formcontrol} name="fname" id="fname" placeholder="First name" aria-label="First name"/>
              </div>
            </div>
  
            <div className={styles.col}>
              <div className={styles.box}>
                <label class="form-label" htmlFor="lname">Last name</label>
                <input required type="text" className={styles.formcontrol} name="lname" id="lname" placeholder="Last name" aria-label="Last name"/>
              </div>
            </div>
          </div>
  
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={styles.box}>
                <label class="form-label" htmlFor="email">Email address</label>
                <input required type="email" className={styles.formcontrol} name="email" id="email" placeholder="email@site.com" aria-label="email@site.com"/>
              </div>
            </div>
  
            <div className={styles.col}>
              <div className={styles.box}>
                <label class="form-label" htmlFor="phone">Phone <span class="form-label-secondary">(Optional)</span></label>
                <input type="text" className={styles.formcontrol} name="phone" id="phone" placeholder="+x(xxx)xxx-xx-xx" aria-label="+x(xxx)xxx-xx-xx"/>
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <label class="form-label" htmlFor="message">Details</label>
            <textarea required className={styles.formcontrol} name="message" id="message" placeholder="Tell us more details ..." aria-label="Tell us more details ..." rows="6"></textarea>
          </div>
  
          <div class="d-grid">
            <button type="submit" className={styles.contactButton}>Send inquiry</button>
          </div>
  
          <div class="text-center">
            <p class="form-text">We'll get back to you in 1-2 business days.</p>
          </div>
        </form>
      </>
    )


    return (
      

      <>
        {success || error ? showError() || showSuccess() : commentForm()}
      </>
    );
  }

  
}









// markup
const ContactPage = () => {
  const data = useStaticQuery(query);
  const domain = data.site.siteMetadata.siteUrl.replace("https://","").replace("www.","")
  
  
  return (
    <Layout>
    <MetaSchemaSEO
      title = "Contact Us"
    />
      <Container>
        <h1>Contact Us</h1>
        <div className={styles.root}>
          <div className={styles.contactCard}>
            <div class="card-body">
              
              <ContactForm slug="contact" project_id={process.env.GATSBY_SITE_ID} domain={domain} page={data.site.siteMetadata.siteUrl+"/contact/"}></ContactForm>
              
            </div>
          </div>
          <div>
            <p>Please use the appropriate contact method to expedite your request.</p>
            <div>
                <p><b>For Advertising or Partnerships:</b> <br/>sales@{domain}</p> 
                <p><b>For Content Recommendations:</b> <br/>tips@{domain}</p> 
                <p><b>For All Other Requests:</b> <br/>info@{domain}</p> 

            </div>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default ContactPage
